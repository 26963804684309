import React,{useState,useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import MailIcon from '@material-ui/icons/Mail';
import CancelIcon from '@material-ui/icons/Cancel';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Loading from './Loading';
import Toast from './Toast';
//import {SiteName} from './Inc';
import {UserContainer} from './UserContainer';

const Login = (props)=> {
    const [states,setStates] = useState({
        email:"",
        isSend:false,
        isLoading:false,
    })
    const [toast,setToast] = useState("");
    const userState = UserContainer.useContainer();
    const signInWithGoogle = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "google",()=>{
            //setIsFinished(true);
			setStates({...states,isLoading:false});
			props.close();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
		});
    }
    const signInWithTwitter = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "twitter",()=>{
            //setIsFinished(true);
			setStates({...states,isLoading:false});
			props.close();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
		});
    }
    const signInWithEmail = ()=>{
        setStates({...states,isLoading:true});
        const email = states.email;
        userState.sendEmail(email,()=>{
            setStates({...states,isLoading:false,isSend:true});
        },()=>{
			setStates({...states,isLoading:false});
            setToast("メールの送信に失敗しました");
        });      
    }

    const validateEmail = ()=>{
        let error = "";
        if( states.email==="" ){
            error = "メールアドレスを入力してください";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(states.email)){
            error = "メールアドレスが正しくありません";
        }
        if( error!=="" ){
            setToast(error);
        } else {
            signInWithEmail();
        }
    }
    const changeEmail = (event) => {
        setStates({...states,"email":event.target.value});
    }
    const logout = ()=>{
        userState.signOut();
		props.close();
    }	
    const closeToast = ()=>{
        setToast("");
    }
	const close = ()=>{
		closeToast();
		props.close();
	}
  
	//document.title = "ログイン｜"+SiteName;

	let provider = "メール";
	if( userState.user ){
		if( userState.user.providerData[0] ){
			if( userState.user.providerData[0].providerId==="twitter.com" ){
				provider = "twitter";
			}
			if( userState.user.providerData[0].providerId==="google.com" ){
				provider = "google";
			}
		}
	}
	
	useEffect( ()=>{
		if( props.open ){
			setStates({email:"",isSend:false,isLoading:false});
		}
	},[props.open]);

	if( !userState.ready ){
        return (null);
    }

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.open}
            onClose={close}
        >
            <Paper className="dialog dialog_login">
            {!states.isSend ? (
				<React.Fragment>
                <DialogTitle>ユーザー</DialogTitle>
                <DialogContent>
                {userState.user &&
                    <DialogContentText>
						あまりにぼんやりと暮らしているので日記くらい毎日書いたらどうだい？と誰かに言われる前に書いています。kwhが名前。<br/>
						<br/>
						2017.3.4　noteでスタート<br/>
						2021.9.4　ここへ引っ越し<br/>
						<Divider style={{margin:"1em 0"}}/>
						<Box className="auth_data">
							<h3 className="withIcon"><VerifiedUserIcon/>アカウント</h3>
							<ul className="personData">
							{userState.user?.displayName &&
								<li>
									{userState.user?.displayName}
								</li>
							}
								<li>{provider}でログイン</li>
							</ul>
							{userState.user?.email &&
							<React.Fragment>
								<h3 className="withIcon"><MailIcon/>メールアドレス</h3>
								<ul className="personData">
									<li>
										{userState.user?.email}
									</li>
								</ul>
								</React.Fragment>
							}													
						</Box>
						<FormControl fullWidth={true}>
            	           <Button variant="contained" color="primary" onClick={logout}>ログアウト</Button>
        	        	</FormControl>
					</DialogContentText>
                }
                {!userState.user &&
                    <React.Fragment>
                    <DialogContentText>
                        ＳＮＳのアカウントまたはメールアドレスでログインして下さい。
					</DialogContentText>
                    <FormControl fullWidth={true} style={{marginBottom:"0.5em"}}>
                        <Button variant="contained" color="default" onClick={signInWithTwitter}>
                            <FontAwesomeIcon icon={['fab','twitter']} style={{marginRight:"0.5em"}}/>Twitter
                        </Button>
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <Button variant="contained" color="default" onClick={signInWithGoogle}>
                            <FontAwesomeIcon icon={['fab','google']} style={{marginRight:"0.5em"}}/>Google
                        </Button>
                    </FormControl>

                    <div className="mailLoginInput">
                        <FormControl fullWidth={true}>
                            <TextField
                                id="email"
                                value={states.email}
                                onChange={changeEmail}
                                margin="dense"
                                placeholder="メールアドレス"
                                //variant="outlined"
                            />
                        </FormControl>
                        
                        <FormControl fullWidth={true} style={{marginBottom:"1em"}}>
                            <Button variant="contained" color="default" onClick={validateEmail}>
                                <FontAwesomeIcon icon="envelope" style={{marginRight:"0.5em"}}/>メールでログイン
                            </Button>
                        </FormControl>
                    </div>


                    </React.Fragment>
                }
					<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
						<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={props.close}><CancelIcon/></IconButton></Tooltip>
					</DialogActions>
				</DialogContent>
				</React.Fragment>
                
            ):(
				<React.Fragment>
					<DialogContent>
						<DialogContentText>
							{states.email}にメールを送信いたしましたのでご確認ください。<br/>
							メールに書かれたURLは1回限り有効です。<br/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" color="default" onClick={close}>閉じる</Button>
					</DialogActions>
				</React.Fragment>
            )}

            </Paper>
            <Toast mes={toast} close={closeToast}/>
            <Loading isLoading={states.isLoading}/>
        </Dialog>
    );
}

export default Login;
