import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import ImageTool from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import imageCompression from "browser-image-compression";

export const EDITOR_JS_TOOLS = {
	embed: Embed,
	table: Table,
	paragraph: {
		class:Paragraph,
		inlineToolbar: true,
	},
	list: List,
	warning: Warning,
	code: Code,
	linkTool: {
		class:LinkTool,
		config: {
			endpoint: process.env.REACT_APP_SYSTEM_URL + 'url/fetch', 
		}
	},
	image: {
		class:ImageTool,
		config: {
			uploader: {
				uploadByFile(file){
					return file2string(file).then((res)=>{
						return {
							success: 1,
							file: {
								url: res,
							},
						};
					},()=>{
						return {
							success: 0,
						};
					});
				},
				uploadByUrl(url){

				},
			},
			actions:[],
		},
	},
	raw: Raw,
	header: {
		class:Header,
		inlineToolbar: true,
	},
	quote: {
		class:Quote,
		inlineToolbar: true,
		config: {
			quotePlaceholder: '',
			captionPlaceholder: 'Quote\'s author',
		},
	},
	marker: Marker,
	checklist: CheckList,
	delimiter: Delimiter,
	inlineCode: InlineCode,
	simpleImage: SimpleImage
}

const file2string = (file)=>{
	return new Promise(async (resolve,reject)=>{
		const reader = new FileReader()
		reader.onabort = () => console.log('file reading was aborted')
		reader.onerror = () => console.log('file reading has failed')
		reader.onload = () => {
			const base64Str = reader.result
			if( base64Str ){
				resolve(base64Str.toString());
			} else {
				reject();
			}
		}
		const compressOptions = {
			maxSizeMB: 2,
			maxWidthOrHeight: 1600,
			fileType: "image/jpeg",
		};
		const compressedFile = await imageCompression(file, compressOptions);
		reader.readAsDataURL(compressedFile);	  
	});
}