import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogFooter from './DialogFooter';

const NoService = (props)=>{
	const close = ()=>{

	}

	return (
		<Dialog open={props.open} onClose={close} aria-labelledby="dialog-noresponse">
			<DialogTitle id="form-dialog-title">休止中</DialogTitle>
			<DialogContent>
				<DialogContentText>
					メンテナンスのためサービスを一時休止しています。<br/>
				</DialogContentText>
				<DialogFooter/>
			</DialogContent>
			<DialogActions>
			</DialogActions>
		</Dialog>
	)
}

export default NoService;