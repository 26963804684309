import {useState,useEffect} from 'react';
import {googleAuth,twitterAuth,auth} from './firebase';
import {createContainer} from "unstated-next";
//import {Inc} from './Inc';

const newMailSettings = {
    url: process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const updateMailSettings = {
    url: process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const useUserState = ()=> {
	const [user, setUser] = useState(null);
	const [ready, setReady] = useState(false);
	const [profile,setProfile] = useState(null);

	const check = (id)=>{
        setReady(true);
	}

	const signIn = async (method,funcSuccess,funcFailure) => {
        //console.log("user",user);
		try {
			if( method==="google" ){
				await auth.signInWithPopup(googleAuth) && funcSuccess && funcSuccess();
			}
			if( method==="twitter" ){
				await auth.signInWithPopup(twitterAuth) && funcSuccess && funcSuccess();
			}
			if( method==="email" ){
				await signInWithEmailLink(funcSuccess,funcFailure);
			}
		} catch (error) {
			console.log(error);
			setUser(null);
			funcFailure && funcFailure();
		}
	}

	const signOut = async () => {
        //console.log("user",user);
		//setProfile(Inc.defaultProfile());
		await auth.signOut();
	}

	const sendEmail = async (email,funcSuccess,funcFailure) => {
		try {
			await auth.sendSignInLinkToEmail(email, newMailSettings)
			.then(function() {
				//console.log("success");
				window.localStorage.setItem('emailForSignIn', email);
				funcSuccess && funcSuccess();
			})
			.catch(function(error) {
				// Some error occurred, you can inspect the code: error.code
				console.log("error",error);
				funcFailure && funcFailure();
			});
		} catch (error) {
			funcFailure && funcFailure();
		}
	}

	const signInWithPassword = async (email,password) => {
		await auth.signInWithEmailAndPassword(email, password)
	}

	const signInWithEmailLink = async (funcSuccess,funcFailure)=>{
		if (auth.isSignInWithEmailLink(window.location.href)) {
			let email = window.localStorage.getItem('emailForSignIn');
			if (!email) {
				funcFailure && funcFailure();
			} else {
				await auth.signInWithEmailLink(email, window.location.href)
				.then(function() {
					window.localStorage.removeItem('emailForSignIn');
					funcSuccess && funcSuccess();
				})
				.catch(function(error) {
					console.log(error);
					funcFailure && funcFailure();
				});
			}
		}
	}

	const updateEmail = async (email,funcSuccess,funcFailure)=>{
		const user = auth.currentUser;
		try {
			if( user ){
				await user.updateEmail(email);
				await user.getIdToken(true);
				await user.sendEmailVerification(updateMailSettings);
				funcSuccess && funcSuccess();
			} else {
				funcFailure && funcFailure();	
			}
		} catch (e) {
			console.log("error",e);
			funcFailure && funcFailure();			
		}
	}

	useEffect(()=>{
		auth.onAuthStateChanged(user => setUser(user));
		auth.onIdTokenChanged(id=>check(id));
	},[user])


	return { user, ready, profile, setProfile, signIn, signOut, sendEmail, updateEmail, signInWithPassword }
}
export const UserContainer = createContainer(useUserState);
