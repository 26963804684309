export default class Ajax {
	static token = "";
	static url = process.env.REACT_APP_SYSTEM_URL;
	static setToken = (tk)=>{
		Ajax.token = tk;
	}
	static send = (path,data,func)=>{
		fetch(Ajax.url+path, {
			method : 'POST',
			body : JSON.stringify(data),
			credentials: "include",
		}).then(response => response.json())
		.then((json) => {
			func(json);
		})
		.catch(err => {
			console.log('rejected:', err); // rejected: TypeError: Failed to fetch
		});
	}

	static hello = async ()=>{
		try {
			return await fetch(Ajax.url+"hello",{
				method: 'POST',
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static newToken = async (userId)=>{
		try {
			const data = {
				UserId: userId,
			};
			return await fetch(Ajax.url+"token/get",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getProfile = async (userId)=>{
		try {
			return await fetch(Ajax.url+"profile/get",{
				method: 'POST',
				body: JSON.stringify({UserId:userId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static addArticle = async (article)=>{
		try {
			const date = article.Date;
			return await fetch(Ajax.url+"article/add",{
				method: 'POST',
				body: JSON.stringify({Article:{...article,Date:null},Date:date,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static updateArticle = async (article)=>{
		try {
			const date = article.Date;
			return await fetch(Ajax.url+"article/update",{
				method: 'POST',
				body: JSON.stringify({Article:{...article,Date:null},Date:date,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static removeArticle = async (articleId,userId)=>{
		try {
			return await fetch(Ajax.url+"article/remove",{
				method: 'POST',
				body: JSON.stringify({ArticleId:articleId,UserId:userId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getArticle = async (articleId,code,userId)=>{
		//console.log("articleId",articleId);
		try {
			return await fetch(Ajax.url+"article/get",{
				method: 'POST',
				body: JSON.stringify({Code:code,ArticleId:articleId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getArticles = async (code,year,month)=>{
		const search = {
			Code: code,
			Year: year,
			Month: month,
			Order: "Date",
			Asc: "desc",
		};
		try {
			return await fetch(Ajax.url+"article/list",{
				method: 'POST',
				body: JSON.stringify({SearchArticle:search,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getArticleMenus = async (code,title,body,ym)=>{
		const search = {
			Code: code,
			Ym: ym,
			Title: title,
			Body: body,
			Order: "Date",
			Asc: "desc",
		};
		try {
			return await fetch(Ajax.url+"article/menu",{
				method: 'POST',
				body: JSON.stringify({SearchArticle:search,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
}