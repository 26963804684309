import React from 'react';

const MailUpdate = ()=>{
	return (
		<div>
			MailUpdate
		</div>
	)
}

export default MailUpdate;