import React,{useState,useEffect} from 'react';
import {Redirect,Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Ajax from './Ajax';
import ArticleView from './ArticleView';
import Loading from './Loading';

const ArticleDetail = (props)=>{
	const [states,setStates] = useState({
		article: {},
		pastId: null,
		futureId: null,
		isLoading: false,
		isRedirect: false,
	});
	const load = async ()=>{
		//if(!userState.user){
		//	return;
		//}
		
		if(!props.code){
			return;
		}
		setStates({...states,isLoading:true});
		const res = await Ajax.getArticle(props.articleId,props.code);
		//console.log(res);
		if( res.ok ){
			window.scrollTo(0,0);
			setStates({...states,article:res.article,pastId:res.pastId,futureId:res.futureId,isLoading:false});
		} else {
			setStates({...states,isRedirect:true});
		}
	}
	const edit = ()=>{
		props.edit(states.article.Id);
	}

	useEffect(()=>{
		if( props.code ){
			load();
		}
	},[props.code,props.articleId,props.ver]);

	if( states.isRedirect ){
		return <Redirect to="/"/>
	}

	if( !states.article.Id || states.isLoading ){
		return <Loading isLoading={true}/>
	}

	const past = "/"+props.code+"/"+states.pastId;
	const future = "/"+props.code+"/"+states.futureId;

	return (
		<div style={{paddingBottom:"6em"}}>
			<ArticleView article={states.article} edit={edit} editable={props.editable}/>
			<Divider style={{marginBottom:"0.25em"}}/>
			<Grid container spacing={0}>
				<Grid item xs={6}>
					<Link to={past} className="link"><Button disabled={states.pastId===""}><ArrowLeftIcon/> 過去へ</Button></Link>
				</Grid>
				<Grid item xs={6} style={{textAlign:"right"}}>
					<Link to={future} className="link"><Button disabled={states.futureId===""}>未来へ <ArrowRightIcon/></Button></Link>
				</Grid>
			</Grid>
		</div>
	)
}

export default ArticleDetail;