import React,{useState,useEffect,useRef} from 'react';
import {Link} from 'react-router-dom';
//import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
//import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
//import FormControl from '@material-ui/core/FormControl';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
//import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//import PersonIcon from '@material-ui/icons/Person';
import HaishinImg from './img/haishin.png';
import R7Img from './img/r7.png';
import StoreImg from './img/store.png';
import Tapir99Img from './img/tapir99.png';
import PortalImg from './img/portal.png';
import Ajax from './Ajax';
//import {UserContainer} from './UserContainer';

//interface DrawerMenuProps {
//	open:boolean,
//	move(target:string):void,
//	toggle(open:boolean):void,
//}

const DrawerMenu = (props)=> {
	const [isOpen,setIsOpen] = useState(false);
	const [states,setStates] = useState({
		menus: null,
		search: {title:"",body:"",ym:""},
		error: {title:"",body:"",ym:""},
		isLoading: true,
	});
	const el = useRef();

	const load = async ()=>{
		setStates({...states,isLoading:true});
		const res = await Ajax.getArticleMenus(props.code,states.search.title,states.search.body,states.search.ym);
		el.current.children[2].scrollTo(0,0);
		if( res.ok ){
			setStates({...states,menus:res.menus,isLoading:false});
		}
	}
	const onOpen = ()=>{
		props.toggle(true);
	}
	const onClose = ()=>{
		props.toggle(false);
	}
	const onChange = (name) => (event) => {
		if (!event) {
			return;
		}
		setStates({
			...states,
			search:{...states.search,[name]:event.target.value},
		});
	};
	const onReset = (name) => (event) => {
		if (!event) {
			return;
		}
		setStates({
			...states,
			search:{...states.search,[name]:""},
		});
	};


	useEffect( ()=>{
		if( props.open ){
			if( !states.menus ) {
				load();
			}
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
		//setIsOpen(props.open);
	},[props.open]);

	let dates = [];
	let today = new Date();
	for( let y=today.getFullYear(); y>=2017; y-- ){
		for( let m=12; m>0; m-- ){
			if( y===today.getFullYear() && m>today.getMonth()+1 ){
				continue;
			}
			if( y===2017 && m<3 ){
				continue;
			}
			dates.push({
				id:y+"-"+m,
				label:y+"年"+("0"+m).substr(-2,2)+"月",
			});
		}
	}

	return (
		<SwipeableDrawer open={isOpen} onOpen={onOpen} onClose={onClose} className="drawer" ref={el}>
			<List
				subheader={
					<ListSubheader disableSticky={true}>検索</ListSubheader>
				}
			>
				<ListItem dense>
					<FormControl fullWidth={true}>
						<TextField
							id="input-title"
							fullWidth={true}
							onChange = {onChange("title")}
							value={states.search.title}
							error={states.error.title!==""}
							helperText={states.error.title}
							label="タイトル"
							type="text"
							InputProps={{
								endAdornment: (
									<InputAdornment position="start" style={{margin:"0"}}>
									<IconButton onClick={onReset("title")} size="small"><CancelIcon /></IconButton>
									</InputAdornment>
								),
							}}					
						/>
					</FormControl>
				</ListItem>
				<ListItem dense>
					<FormControl fullWidth={true}>
						<TextField
							id="input-body"
							fullWidth={true}
							onChange = {onChange("body")}
							value={states.search.body}
							error={states.error.body!==""}
							helperText={states.error.body}
							label="本文"
							type="text"
							InputProps={{
								endAdornment: (
									<InputAdornment position="start" style={{margin:"0"}}>
									<IconButton onClick={onReset("body")} size="small"><CancelIcon /></IconButton>
									</InputAdornment>
								),
							}}					
						/>
					</FormControl>
				</ListItem>
				<ListItem dense>
					<FormControl fullWidth={true}>
						<TextField
							id="input-ym"
							select
							value={states.search.ym}
							onChange={onChange("ym")}
							label="年月"
						>
							<MenuItem key="ym" value="">　</MenuItem>
						{dates.map((data,index) => {
							const key = "ym"+index;
							return (
							<MenuItem key={key} value={data.id}>{data.label}</MenuItem>
							);
						})}
						</TextField>				
					</FormControl>
				</ListItem>
				<ListItem>
					<FormControl fullWidth={true}>
						<Button color="primary" variant="outlined" onClick={load}>日記を検索</Button>
					</FormControl>
				</ListItem>
			</List>
			<Divider style={{marginTop:"2em"}}/>
			<List
				subheader={
					<ListSubheader disableSticky={true}>目次（検索結果）</ListSubheader>
				}
			>
				{states.menus && states.menus.map((menu,index)=>{
					const key = "menu"+index;
					if( index<50 ){
						const to = "/" + props.code + "/" + menu.Id;
						const d = menu.Date.split("T");
						return (
							<Link to={to} key={key} onClick={onClose} className="link">
								<ListItem button>
									<ListItemText primary={menu.Title} secondary={d[0]}/>
								</ListItem>
							</Link>
						);
					} else {
						return <ListItem key="last" className="menuOverLimit">※件数が多すぎるので50件だけ表示しました</ListItem>
					}
				})}
				{states.isLoading && 
				<ListItem style={{textAlign:"center"}}><CircularProgress color="primary"/></ListItem>
				}
				{(!states.isLoading && !states.menus) &&
				<ListItem style={{textAlign:"center",fontSize:"90%",color:"#663333"}}>※検索条件に該当する日記はありません</ListItem>
				}
			</List>
			<Divider style={{marginTop:"2em"}}/>
			<List
				component="nav"
				subheader={
					<ListSubheader disableSticky={true}>姉妹サイト</ListSubheader>
				}
			>
				<a href="https://store.retro-biz.com/" rel="noopener noreferrer" target="_blank" className="link"><ListItem button>
					<ListItemAvatar>
						<Avatar>
							<img src={StoreImg} alt="架空ストアロゴ" style={{width:"40px"}}/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="架空ストア" secondary="雑貨委託・本当に売ってる架空のお店"/>
					<ListItemSecondaryAction>
						<IconButton><ArrowForwardIcon/></IconButton>
					</ListItemSecondaryAction>

				</ListItem></a>
				<a href="https://liveguide.retro-ink.com/" rel="noopener noreferrer" target="_blank" className="link"><ListItem button>
					<ListItemAvatar>
						<Avatar>
							<img src={HaishinImg} alt="ハイシン！ロゴ" style={{width:"40px"}}/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="ハイシン！" secondary="音楽・落語・演劇の生配信番組ガイド"/>
					<ListItemSecondaryAction>
						<IconButton><ArrowForwardIcon/></IconButton>
					</ListItemSecondaryAction>

				</ListItem></a>
				<a href="https://home.tapir99.info/" rel="noopener noreferrer" target="_blank" className="link"><ListItem button>
					<ListItemAvatar>
						<Avatar>
							<img src={Tapir99Img} alt="獏と九九ロゴ" style={{width:"40px"}}/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="獏と九九" secondary="出演予定のまとめページ作成"/>
					<ListItemSecondaryAction>
						<IconButton><ArrowForwardIcon/></IconButton>
					</ListItemSecondaryAction>

				</ListItem></a>
				<a href="https://tools.r7ticket.jp/" rel="noopener noreferrer" target="_blank" className="link"><ListItem button>
					<ListItemAvatar>
						<Avatar>
							<img src={R7Img} alt="R7 TICKET SERVICEロゴ" style={{width:"40px"}}/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="R7 TICKET SERVICE" secondary="当日精算に特化したチケット予約サービス"/>
					<ListItemSecondaryAction>
						<IconButton><ArrowForwardIcon/></IconButton>
					</ListItemSecondaryAction>

				</ListItem></a>
				<a href="https://goisagi.retro-ink.com/" rel="noopener noreferrer" target="_blank" className="link"><ListItem button>
					<ListItemAvatar>
						<Avatar>
							<img src={PortalImg} alt="レトロインクの未完成ポータルロゴ" style={{width:"40px"}}/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="レトロインクの未完成ポータル" secondary="わからないことはわかるんだ"/>
					<ListItemSecondaryAction>
						<IconButton><ArrowForwardIcon/></IconButton>
					</ListItemSecondaryAction>

				</ListItem></a>
			</List>
			<Divider style={{marginTop:"2em"}}/>
			<div className="signature">
				retro-ink &copy; 2021<br/>
				<a href="https://www.retro-ink.com/" rel="noopener noreferrer" target="_blank" className="link">https://www.retro-ink.com/</a>
			</div>
		</SwipeableDrawer>
    );
}

export default DrawerMenu;
