import React from 'react';

const MailNew = ()=>{
	return (
		<div>
			MailNew
		</div>
	)
}

export default MailNew;