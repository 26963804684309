import React from 'react';

const DialogFooter = ()=> {
	return (
		<div className="dialogFooter">
			<div className="logo">
				nikki<br/>
			</div>
			<div className="copyright">
				retro-ink &copy;2021
			</div>
		</div>
    );
}

export default DialogFooter;
