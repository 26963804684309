import React,{useState,useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import Login from './Login';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Ajax from './Ajax';
import Loading from './Loading';
import Toast from './Toast';
import {UserContainer} from './UserContainer';
import "./App.scss";

const Enter = ()=>{
	const userState = UserContainer.useContainer();
	const [redirectTo,setRedirectTo] = useState("");
	const [open,setOpen] = useState(false);
	const [song,setSong] = useState("");
	const [toast,setToast] = useState("");
	const [isLogin,setIsLogin] = useState(false);

	const openDialog = ()=>{
		setOpen(true);
	}
	const closeDialog = ()=>{
		setOpen(false);
	}
	const onChange = (event)=>{
		setSong(event.target.value);
	}
	const validate = ()=>{
		if( song!=="パンパカクルージング" ){
			setToast("いい歌ですね！");
			return;
		}
		setOpen(false);
		openLogin();
	}
	const closeToast = ()=>{
		setToast("");
	}
	const openLogin = ()=>{
		setIsLogin(true);
	}
	const closeLogin = ()=>{
		setIsLogin(false);
	}
	const setup = async ()=>{
		if( userState.user && userState.user.uid!=="" ){
			//console.log("loadProfile1");
			const res = await Ajax.getProfile(userState.user.uid);
			if( res.ok ){
				userState.setProfile(res.profile);
				setRedirectTo(res.profile.Code);
			}
		}
	}

	useEffect( ()=>{
		setup();
	},[userState.ready,userState.user?.uid])

	if( redirectTo!=="" ){
		return <Redirect to={redirectTo}/>
	}

	if( !userState.ready ){
		return <Loading isLoading={true}/>
	}

	return (
		<div className="main">
			<div className="enter">
				<h1>nikki</h1>
				<div className="notice">
					ひきこもり気味の日記サービス<br/>
					とにかく毎日書きたい人に良く効く<br/>
				</div>
				<div style={{marginTop:"2em",textAlign:"center"}}>
					<Button color="default" variant="outlined" onClick={openDialog}>ログイン</Button>
				</div>
			</div>
			<Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">ちょほいとまちなは</DialogTitle>
				<DialogContent>
				<DialogContentText>
					このサービスは開発途上のため開発者以外は日記を書けません。<br/>
					もしあなたが開発者ならば開発者しか知らない秘密の鼻歌を歌って下さい。<br/>
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="input-song"
					label="秘密の鼻歌"
					type="text"
					onChange={onChange}
					value={song}
					fullWidth
				/>
				</DialogContent>
				<DialogActions>
				<Button onClick={closeDialog} color="primary">
					やめておく
				</Button>
				<Button onClick={validate} color="primary" disabled={song===""}>
					歌っちゃう
				</Button>
				</DialogActions>
			</Dialog>
			<Login open={isLogin} close={closeLogin}/>
			<Toast mes={toast} close={closeToast}/>
		</div>
	)
}

export default Enter;