import React,{useState,useEffect} from 'react';
import {useParams,useHistory} from 'react-router-dom';
import Login from './Login';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import ListIcon from '@material-ui/icons/List';
import HomeIcon from '@material-ui/icons/Home';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
//import ArticleList from './ArticleList';
import ArticleInput from './ArticleInput';
import ArticleDetail from './ArticleDetail';
import DrawerMenu from './DrawerMenu';
import Profile from './Profile';
import Ajax from './Ajax';
import {UserContainer} from './UserContainer';
import "./App.scss";

const Main = ()=>{
	const [states,setStates] = useState({
		editId: null,
		code: null,
		profile: null,
		isInput: false,
		isLogin: false,
		isLoading: false,
		isRedirect: false,
		isProfile: false,
		isUser: false,
		ver: 0,
	});
	const userState = UserContainer.useContainer();
	const [isDrawer,setIsDraswer] = useState(false);
	//const [navi,setNavi] = useState(9);
	const history = useHistory();
	const {code,articleId} = useParams();

	const openLogin = ()=>{
		setStates({...states,isLogin:true});
	}
	const closeLogin = ()=>{
		setStates({...states,isLogin:false});
	}
	const openProfile = ()=>{
		setStates({...states,isProfile:true});
	}
	const closeProfile = ()=>{
		setStates({...states,isProfile:false});
	}

	const openInput = ()=>{
		setStates({...states,isInput:true,editId:null,article:null});
	}
	const closeInput = ()=>{
		setStates({...states,isInput:false,article:null});
	}
	const openEdit = (id)=>{
		setStates({...states,isInput:true,editId:id});
	}
	const refreshArticle = (id)=>{
		//setStates({...states,isInput:false,article:article,editId:null,ver:states.ver+1});
		setStates({...states,isInput:false,editId:null,ver:states.ver+1});
		if( id!==states.editId ){
			if( id!=="" ){
				history.push("/"+states.code+"/"+id);
			} else {
				history.push("/"+states.code);
			}
		}
	}
	const toggleDrawer = (open)=>{
		setIsDraswer(open);
	}
	const goTop = ()=>{
		history.push("/"+states.code);
	}
	const setup = async ()=>{
		if(userState.profile===null && userState.user && userState.user.uid!=="" ){
			const res = await Ajax.getProfile(userState.user.uid);
			if( res.ok ){
				userState.setProfile(res.profile);
			}
		} else if(userState.profile!==null && userState.ready && !userState.user){
			userState.setProfile(null);
		}
	}
	const checkUser = async ()=>{
		if( code ){
			let isUser = false;
			if( userState.profile?.Code===code ){
				isUser = true;
			}
			setStates({...states,code:code,isUser:isUser});
		} else if(userState.profile && userState.profile.Code!=="" ) {
			setStates({...states,code:userState.profile.Code,isUser:true});
		}
	}

	useEffect( ()=>{
		setup();
	},[userState.ready,userState.user?.uid])

	useEffect( ()=>{
		checkUser();
	},[code,userState.profile?.Code])

	return (
		<div className="main">
			<ArticleDetail code={states.code} articleId={articleId} edit={openEdit} editable={states.isUser} ver={states.ver}/>
			<BottomNavigation
				value={9}
				showLabels
				style={{position:"fixed",bottom:"0",left:"0",width:"100%"}}
				>
				<BottomNavigationAction label="トップ" icon={<HomeIcon />} onClick={goTop}/>
				<BottomNavigationAction label="目次" icon={<ListIcon />}  onClick={toggleDrawer}/>
				{states.isUser ? (
				<BottomNavigationAction label="ユーザー" icon={<PersonIcon />} onClick={openLogin}/>
				):(
				<BottomNavigationAction label="ご挨拶" icon={<PersonIcon />} onClick={openProfile}/>
				)}
			</BottomNavigation>
			{states.isUser &&
			<Fab size="small" color="primary" style={{position:"fixed",top:"1em",right:"1em"}} onClick={openInput}>
				<AddIcon style={{color:"#FFFFFF"}}/>
			</Fab>
			}
			<Login open={states.isLogin} close={closeLogin}/>
			<Profile open={states.isProfile} close={closeProfile}/>
			<DrawerMenu code={states.code} open={isDrawer} toggle={toggleDrawer}/>
			<ArticleInput open={states.isInput} close={closeInput} refresh={refreshArticle} articleId={states.editId}/>
		</div>
	)
}

export default Main;