import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
//import CloseIcon from '@material-ui/icons/Close';

const Profile = (props)=> {
    return (
		<Dialog open={props.open} onClose={props.close} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">nikki 0001 - kwh</DialogTitle>
			<DialogContent>
			<DialogContentText>
				あまりにぼんやりと暮らしているので日記くらい毎日書いたらどうだい？と誰かに言われる前に書いています。kwhが名前。<br/>
				<br/>
				2017.3.4　noteでスタート<br/>
				2021.9.4　ここへ引っ越し<br/>
			</DialogContentText>

			</DialogContent>
			<DialogActions>
			<Button onClick={props.close} color="primary">
				OK
			</Button>
			</DialogActions>
		</Dialog>
    )
}

export default Profile;