import React from 'react';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const ArticleView = (props)=>{
	const createMarkup = htmlString => ({ __html: htmlString });

	const treatDate = (d)=>{
		if(!d){
			return "";
		}
		const sp1 = d.split("T");
		if( sp1.length===2 ){
			const sp2 = sp1[1].split("+");
			return sp1[0] + " " + sp2[0];
		} else {
			return "";
		}
	}

	if( !props.article ){
		return <div/>;
	}
	if( !props.article.Body || props.article.Body==="" ){
		return <div/>;
	}

	const body = JSON.parse(props.article.Body);
	const d = props.article.Date.split("T");
	return (
		<div className="article">
			<h1>{props.article.Title}</h1>
			<div className="date">
				{props.editable ? (
				<Button size="small" variant="text" style={{margin:"0",padding:"0",fontSize:"90%"}} onClick={props.edit}>{d[0]}｜EDIT</Button>
				):(
				<span>{d[0]}</span>
				)}
			</div>
			<div className="body">
				{body.blocks.map((block,index)=>{
					const key = "block"+index;
					if( block.type==="paragraph" ){
						return (
							<p id={block.id} key={key} className="paragraph"　dangerouslySetInnerHTML={createMarkup(block.data.text)}/>
						)
					} else if( block.type==="header" && block.data.level===2 ){
						return (
							<h2 id={block.id} key={key} className="paragraph">{block.data.text}</h2>
						)
					} else if( block.type==="header" && block.data.level===3 ){
						return (
							<h3 id={block.id} key={key} className="paragraph">{block.data.text}</h3>
						)
					} else if( block.type==="list" && block.data.style==="ordered" ){
						return (
							<ol id={block.id} key={key} className="list">{block.data.items.map((item,index2)=>{
								const key2 = "item"+index2;
								return (
									<li key={key2}>{item}</li>
								)
							})}</ol>
						)
					} else if( block.type==="list" && block.data.style==="unordered" ){
						return (
							<ul id={block.id} key={key} className="list">{block.data.items.map((item,index2)=>{
								const key2 = "item"+index2;
								return (
									<li key={key2}>{item}</li>
								)
							})}</ul>
						)
					} else if( block.type==="checklist" ){
						return (
							<ul id={block.id} key={key} className="checklist">{block.data.items.map((item,index2)=>{
								const key2 = "item"+index2;
								var check;
								if( item.checked ){
									check = <CheckCircleIcon style={{color:"#66CCFF",verticalAlign:"bottom"}}/>
								} else {
									check = <RadioButtonUncheckedIcon style={{color:"#999999",verticalAlign:"bottom"}}/>
								}
								return (
									<li key={key2}>{check} {item.text}</li>
								)
							})}</ul>
						)
					} else if( block.type==="warning" ){
						return (
							<div id={block.id} key={key} className="warning">
								<h4>{block.data.title}</h4>
								<p>{block.data.message}</p>
							</div>
						)
					} else if( block.type==="quote" ){
						let classname = "quote";
						if( block.data.alignment==="left" ){
							classname = classname + " quote-left";
						}
						if( block.data.alignment==="center" ){
							classname = classname + " quote-center";
						}
						let text = block.data.text.trim().replace("<br>","");
						let caption = block.data.caption.trim().replace("<br>","");
						return (
							<div id={block.id} key={key} className={classname}>
								<p>{text}</p>
								{caption !== "" &&
								<p className="caption">{caption}</p>
								}
							</div>
						)
					} else if( block.type==="code" ){
						return (
							<div id={block.id} key={key} className="code">
								<pre>{block.data.code}</pre>
							</div>
						)
					} else if( block.type==="image" ){
						let classname = "image";
						if( block.data.withBorder ){
							classname = classname + " image-border";
						}
						if( block.data.withBackground ){
							classname = classname + " image-background";
						}
						if( block.data.stretched ){
							classname = classname + " image-stretched";
						}
						let caption = block.data.caption.trim().replace("<br>","");
						return (
							<div id={block.id} key={key} className={classname}>
								<img src={block.data.file.url} alt={caption} title={caption}/>
								{block.data.caption!=="" &&
								<div className="caption">{caption}</div>
								}
							</div>
						)
					} else if( block.type==="raw" ){
						return (
							<div id={block.id} key={key} className="raw">
								<pre>{block.data.html}</pre>
							</div>
						)
					} else if( block.type==="delimiter" ){
						return (
							<div id={block.id} key={key} className="delimiter">＊＊＊</div>
						)
					} else if( block.type==="linkTool" ){
						const url = block.data.meta.image.url;
						return (
							<a href={block.data.link} rel="noopener noreferrer" target="_blank" className="linkTool">
								<div id={block.id} key={key} className="inner" style={{backgroundImage:`url(${url})`}}>
									<div className="text">
										<div className="title">{block.data.meta.title}</div>
										<div className="description">{block.data.meta.description}</div>
										<div className="link">{block.data.link}</div>
									</div>
								</div>
							</a>
						)
					} else if( block.type==="embed" ){
						let height = block.data.height + 120;
						return (
							<div id={block.id} key={key} className="embed">
								<iframe title="embed" src={block.data.embed} style={{width:"100%"}} width={block.data.width} height={height} scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true'/>
							</div>
						)
					} else {
						return (
							<div>type:{block.type} / body:{JSON.stringify(block)}</div>
						)
					}
				})}

			</div>
			<div className="data">
				CreatedAt: {treatDate(props.article.CreatedAt)}<br/>
				UpdatedAt: {treatDate(props.article.UpdatedAt)}<br/>
			</div>
		</div>
	)
}

export default ArticleView;