import React,{useState,useEffect} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'//yarn add @types/react-router-dom
import MailNew from './MailNew';
import MailUpdate from './MailUpdate';
import OldVersion from './OldVersion';
import NoService from './NoService';
import NoResponse from './NoResponse';
import Main from './Main';
import Enter from './Enter';
import {UserContainer} from './UserContainer';
import Ajax from './Ajax';

const App = ()=>{
	const basename = process.env.REACT_APP_BASENAME;
	const [version,setVersion] = useState("");
	const [available,setAvailable] = useState(true);
	const [serverError,setServerError] = useState(false);
  
	const hello = async ()=>{
		await Ajax.hello().then((res)=>{
			//console.log(res);
			if( res.ok ){
				if( res.version!==process.env.REACT_APP_VERSION ){
					setVersion(res.version);
				} else if( res.available===false ){
					setAvailable(false);
				}
			} else {
				setServerError(true);
			}
		}).catch((error)=>{
			console.log(error);
			setServerError(true);
		});
	}

	useEffect(()=>{
		hello();
	},[]);

	return (
		<BrowserRouter basename={basename}>
			<UserContainer.Provider>
				<Switch>
					<Route exact path='/' render={()=><Enter/>}/>
					<Route exact path='/:code' render={()=><Main/>}/>
					<Route exact path='/:code/:articleId' render={()=><Main/>}/>
					<Route exact path='/mail/new' render={()=><MailNew/>}/>
					<Route exact path='/mail/update' render={()=><MailUpdate/>}/>              
				</Switch>
			</UserContainer.Provider>
			<OldVersion latest={version} open={version!==""}/>
			<NoService open={!available}/>
			<NoResponse open={serverError}/>
		</BrowserRouter>
	);
}

export default App;
