import React,{useState,useRef,useEffect} from 'react';
//import {Link,useParams} from 'react-router-dom';
import EditorJs from 'react-editor-js';
import { EDITOR_JS_TOOLS } from './tools'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import {UserContainer} from './UserContainer';
import Ajax from './Ajax';
import Toast from './Toast';
import Loading from './Loading';

var data = {
	time: 0,
	blocks: [
		{
			type: "paragraph",
			data: {
			  text:
				""
			}
		},		
	],
	version: "0.00.00"
};

const storageKey = "latestarticle";

const ArticleInput = (props)=>{
	const instanceRef = useRef(null)
	const userState = UserContainer.useContainer();
	//const {articleId} = useParams();
	const [states,setStates] = useState({
		article: {
			Title: "",
			Date: "",
		},
		error: {
			Title: "",
			Date: "",
		},
		isRemove: false,
		isLoading: true,
	});
	const [toast,setToast] = useState("");
	const storageIntervalId = useRef(null);
	const refStates = useRef(null);
	refStates.current = states;

	const readyEditor = (instance)=>{
		instanceRef.current = instance;

		if( props.articleId ){
			load();
		} else {
			const saveData = localStorage.getItem(storageKey);
			if( saveData ){
				let editData = JSON.parse(saveData);
				console.log(editData);
				if( editData.article ){
					if( editData.articleId===null ){
						if( editData.body.blocks.length===0 ){
							editData.body.blocks = [
								{
									type: "paragraph",
									data: {
									text:
										""
									}
								},						
							]
						}
						instanceRef.current.clear();
						instanceRef.current.render(editData.body);
						setStates({...states,isLoading:false,isRemove:false,article:editData.article});
						return;
					}
				}
			}
		}
		setStates({...states,isLoading:false,isRemove:false});
	}

	const clearData = ()=>{
		setStates({...states,
			article: {
				Title: "",
				Date: "",
			},
			error: {
				Title: "",
				Date: "",
			},			
		});
	};

	const load = async ()=>{
		if( !userState || !userState.user ){
			return;
		}
		if(!props.articleId || props.articleId==="" ){
			return;
		}
		//console.log("load"+props.articleId);
		setStates({...states,isLoading:true});
		const res = await Ajax.getArticle(props.articleId,userState.user.uid);
		if( res.ok ){
			instanceRef.current.clear();
			instanceRef.current.render(JSON.parse(res.article.Body));	
			let date = "";
			const sp = res.article.Date.split("T");
			if( sp.length===2 ){
				date = sp[0];
			} else {
				date = res.article.Date;
			}
			setStates({...states,article:{...res.article,Date:date},error:{Title:"",Date:""},isLoading:false,isRemove:false});
		} else {
			setStates({...states,isLoading:false,isRemove:false});
			setToast("error");
		}
	}

	const validate = async ()=>{
		let error = {
			Title: "",
			Date: "",
		};
		let isError = false;
		let article = states.article;
		article.Title = article.Title.trim();
		if( article.Title==="" ){
			error.Title = "省略できません";
			isError = true;
		} else if( article.Title.length>50 ){
			error.Title = "50文字以内にしてください";
			isError = true;
		}
		article.Date = article.Date.trim();
		if( article.Date==="" ){
			error.Date = "省略できません";
			isError = true;
		}
		if( isError ){
			setStates({...states,article:article,error:error});
		} else {
			send(article);
		}
	}

	const send = async (article)=>{
		if( !userState || !userState.user ){
			return;
		}

		setStates({...states,article:article,error:{Title:"",Date:""},isLoading:true});

		const editData = await instanceRef.current.save();
		article.Body = JSON.stringify(editData);
		article.UserId = userState.user.uid;

		var res;
		if( props.articleId ){
			article.Id = props.articleId;
			res = await Ajax.updateArticle(article);
		} else {
			res = await Ajax.addArticle(article);
			article.Id = res.id;
		}
		
		if( res.ok ){
			setStates({...states,article:{Title:"",Date:""},error:{Title:"",Date:""},isLoading:false});
			//localStorage.setItem("editData", "");
			//localStorage.setItem("editId", "");
			localStorage.removeItem(storageKey);
			props.refresh(article.Id);
		} else {
			setStates({...states,article:article,error:{Title:"",Date:""},isLoading:false});
			setToast("error");
		}
	}

	const changeEditor = async ()=>{
		//const editData = await instanceRef.current.save();
		//localStorage.setItem("editData", JSON.stringify(editData));
		//localStorage.setItem("editId", props.articleId);
	}

	//const resetEditor = async ()=>{
	//	instanceRef.current.clear();
	//	instanceRef.current.render(data);
	//}
	const close = ()=>{
		//console.log("close");
		clearData();
		props.close();
		return;
	}
	//const closeFinished = ()=>{
	//	return;
	//}
	const closeToast = ()=>{
		setToast("");
	}
	const onChange = (name) => (event) => {
		if (!event) {
			return;
		}
		//console.log("change",name);
		setStates({
			...states,
			article:{...states.article,[name]:event.target.value},
		});
	};
	const openRemove = ()=>{
		setStates({...states,isRemove:true});
	}
	const closeRemove = ()=>{
		setStates({...states,isRemove:false});
	}
	const remove = async ()=>{
		const res = await Ajax.removeArticle(props.articleId,userState.user?.uid);
		setStates({...states,isRemove:false});
		if( res.ok ){
			props.refresh("");
		} else {
			setToast("error");
		}
	}
	const autosave = async ()=>{
		if(!instanceRef.current){
			return;
		}
		const editData = await instanceRef.current.save();
		const saveData = {
			articleId: props.articleId,
			article: refStates.current.article,
			body: editData,
		};
		localStorage.setItem(storageKey,JSON.stringify(saveData));
	}
	const startInterval = ()=>{
		if(!props.open){
			return;
		}
		if(storageIntervalId.current!==null){
			return;
		}
		storageIntervalId.current = setInterval(()=>{
			autosave();
		},5000);
	}
	const stopInterval = ()=>{
		if(storageIntervalId.current===null){
			return;
		}
		clearInterval(storageIntervalId.current);
		storageIntervalId.current = null;
	}

	useEffect(()=>{
		if(!props.open){
			stopInterval();
		} else {
			startInterval();
		}
		return ()=>{
			stopInterval();
		}
	},[props.open])

	return (
		<React.Fragment>
			<Dialog
				aria-labelledby="dialog-input"
				aria-describedby="dialog-input"
				open={props.open}
				onClose={close}
				fullScreen={true}
			>
				<DialogContent>
					<div className="articleInput">
					<Grid container spacing={1}>
						<Grid item xs={12} md={9}>
							<InputLabel htmlFor="input-title">タイトル</InputLabel>
							<TextField
								id="input-title"
								fullWidth={true}
								onChange = {onChange("Title")}
								value={states.article.Title}
								error={states.error.Title!==""}
								helperText={states.error.Title}
								type="text"
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<InputLabel htmlFor="input-date">日付</InputLabel>
							<TextField
								id="input-date"
								fullWidth={true}
								onChange = {onChange("Date")}
								value={states.article.Date}
								error={states.error.Date!==""}
								helperText={states.error.Date}
								type="date"
								InputLabelProps={{
									shrink: true,
								}}				
							/>
						</Grid>
						<Grid item xs={12} style={{borderBottom:"1px solid #888888"}}>
							<EditorJs id="input-body" data={data} tools={EDITOR_JS_TOOLS} enableReInitialize={false} onReady={readyEditor} onChange={changeEditor}/>
						</Grid>
						<Grid item xs={6} style={{textAlign:"left"}}>
							<Button variant="outlined" onClick={close}>中止</Button>
							<Button color="secondary" variant="outlined" onClick={openRemove} disabled={!props.articleId}>削除</Button>
						</Grid>
						<Grid item xs={6} style={{textAlign:"right"}}>
							<Button color="primary" variant="contained" onClick={validate}>確認</Button>
						</Grid>
					</Grid>
					</div>
				</DialogContent>
			</Dialog>
			<Dialog
				aria-labelledby="dialog-remove"
				aria-describedby="dialog-remove"
				open={states.isRemove}
				onClose={closeRemove}
			>
				<DialogTitle id="dialog-remove-title">ページの削除</DialogTitle>
				<DialogContent>
					本当に良いのですか？このページを削除してしまって。
				</DialogContent>
				<DialogActions>
				<Button onClick={closeRemove} color="primary">
					やめておく
				</Button>
				<Button onClick={remove} color="primary">
					本当に良い
				</Button>
				</DialogActions>
			</Dialog>
			<Loading isLoading={states.isLoading && props.open}/>
			<Toast mes={toast} close={closeToast}/>
		</React.Fragment>
	)
}

export default ArticleInput;